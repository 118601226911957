import { defineStore } from "pinia";
import trainingsApi from "@/api/trainingsApi";
import usersApi from "@/api/usersApi";
import {AxiosResponse} from "axios";

export const storeTraining = defineStore({
    id: "storeTraining",
    state: () => ({
    }),

    actions: {
        getTraining(trainingId: string) {
            return trainingsApi.getTraining(trainingId).then((response: AxiosResponse) => {
                return response.data;
            });
        },
        getAllTrainings(start: number, end: number) {
            return trainingsApi.getAllTrainings(start, end).then((response: AxiosResponse) => {
                return response.data;
            });
        },
        subscribe(trainingId: string, userIds: Array<string>, modulesSubscriptions: Array<Object>) {
            return trainingsApi.subscribe(trainingId, userIds, modulesSubscriptions).then((response: AxiosResponse) => {
                return response.data;
            });
        },
        getTrainingPath(trainingPathId: string) {
            return trainingsApi.getTrainingPath(trainingPathId).then((response: AxiosResponse) => {
                return response.data;
            });
        },
        getAllTrainingPaths(start: number, end: number) {
            return trainingsApi.getAllTrainingPaths(start, end).then((response: AxiosResponse) => {
                return response.data;
            });
        },
        getAllTrainingSubscriptions(start: number, end: number) {
            return trainingsApi.getAllTrainingSubscriptions(start, end).then((response: AxiosResponse) => {
                return response.data;
            });
        },
        getSubscription(subscriptionId: string) {
            return trainingsApi.getSubscription(subscriptionId).then((response: AxiosResponse) => {
                return response.data;
            });
        },
    },
});
