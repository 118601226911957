import bank from '@/api/bank.json';
import {HTTP} from "@/http";
import {AxiosResponse} from "axios";
import {sleep} from "@/functions/utils";

export default {
    getTraining(trainingId: string) {
        return HTTP.get(`/trainings/${trainingId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getAllTrainings(start: number, end: number) {
        return HTTP.get(`/trainings`, )
            .then(async (response: AxiosResponse) => {
                //await sleep(1000);
                return response;
            }).catch((response) => {
                return response;
            });
    },

    subscribe(trainingId: string, userIds: Array<string>, modulesSubscriptions: Array<Object>) {
        return HTTP.post(`/training-subscriptions`, { trainingId, userIds, modulesSubscriptions })
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getTrainingPath(trainingPathId: string) {
        return HTTP.get(`/training-path/${trainingPathId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getAllTrainingPaths(start: number, end: number) {
        return HTTP.get(`/training-path`, )
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getAllTrainingSubscriptions(start: number, end: number) {
        return HTTP.get(`/training-subscriptions`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

    getSubscription(subscriptionId: string) {
        return HTTP.get(`/training-subscriptions/${subscriptionId}`)
            .then(async (response: AxiosResponse) => {
                return response;
            }).catch((response) => {
                return response;
            });
    },

};
